$primary: #b2ad93;
$secondary: #bcb7a0;
$dark: #4c4c4b;
$white: #ffffff;

h1 {
  color: $secondary;
  text-align: center;
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

body {
  background-color: $primary;
}

.nav-tabs .nav-link {
  color: $dark;
}

.jumbotron {
  background-color: $white;
  color: $dark;
  border: $dark 1px solid;
}

.jumbotron ul li::marker {
  color: $secondary;
}

.jumbotron a {
  text-decoration: underline;
  color: $dark;
  cursor: pointer;
}

a[href^="tel:"]::before {
  font-family: "Font Awesome 5 Free";
  content: "\f095";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

a[href^="mailto:"]::before {
  font-family: "Font Awesome 5 Free";
  content: "\f0e0";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

.disableBorder {
  border: none;
}
